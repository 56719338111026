import "./testimonials-component.css";

const TestimonialsComponent = (params) => {
  return (
    <div className="testimonials-component">
      <h5 id="date">{params.date}</h5>
      <p id="review">{params.review}</p>
      <h4 id="user">{params.user}</h4>
    </div>
  );
};

export default TestimonialsComponent;
