import "./footer-component.css";
import { NavLink } from "react-router-dom";
import logoSimple from "../../images/logo-simple.svg";
import igSocial from "../../images/instagram-social.svg";
import fbSocial from "../../images/fb-social.svg";
import whSocial from "../../images/whatsapp-social.svg";

import { ga, event } from "../../App";

const onAboutClick = () => {
  event(ga, 'screen_view', {
    name: 'About'
  });
}

const onLocationClick = () => {
  event(ga, 'screen_view', {
    name: 'Location'
  });
}

const onBookClick = () => {
  event(ga, 'screen_view', {
    name: 'Book'
  });
}

const onContactClick = () => {
  event(ga, 'screen_view', {
    name: 'Contact'
  });
}

const FooterComponent = () => {
  return (
    <div>
      <div className="footer-component">
        <div className="footer-item">
          <h5>HOME</h5>
          <ul>
            {/* <li>
              <NavLink to="/amenities">
                Amenities
              </NavLink>
            </li> */}
            <li>
              <NavLink onClick={onAboutClick} to="/about">
                About us
              </NavLink>
            </li>
            <li>
              <NavLink onClick={onLocationClick} to="/location">
                Location
              </NavLink>
            </li>
          </ul>
        </div>
    
        <div className="footer-item">
          <h5>RESERVATION</h5>
          <ul>
            <li>
              <a onClick={onBookClick} href="https://feriehome.com/en/villas-croatia/split/villa-nocturno">Book now</a>
            </li>
            <li>
              <NavLink onClick={onContactClick} to="/contact">
                Contact us
              </NavLink>
            </li>
          </ul>
        </div>

      </div>
      <div className="signature-container">
        <p>
        <a href="mailto:info@villa-nocturno.com">info@villa-nocturno.com</a>{" | +385 (0) 95 918 7347 | Duge Njive 20F, 21312 Podstrana"}
        </p>
        <div className="social-logo-container">
          <a href="https://www.instagram.com/villanocturno/" target="_blank">
            <div className="first-social" style={{ backgroundImage: `url(${igSocial})` }}></div>
          </a>
          <a href="https://www.facebook.com/villanocturno/" target="_blank">
            <div className="social" style={{ backgroundImage: `url(${fbSocial})` }}></div>
          </a>
          <a href="https://wa.me/+385959187347" target="_blank">
            <div className="social" style={{ backgroundImage: `url(${whSocial})` }}></div>
          </a>
        </div>
      </div>
      <div className="footer-logo-container">
        <img
          src={logoSimple}
          alt="footer image"
        />
      </div>
    </div>
  );
};

export default FooterComponent;
