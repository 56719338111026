import FooterComponent from "../components/footer/FooterComponent";
import HomeWelcomeComponent from "../components/home/HomeWelcomeComponent";
import HomeFeaturesLayout from "../layouts/home-feature/HomeFeaturesLayout";
import SimpleFeatureComponent from "../components/home/SimpleFeatureComponent";
import ImagesSliderComponent from "../components/slider/ImagesSliderComponent";
import mainVillaDarkImage from "../images/villa-header-image_2.webp";

const HomePage = () => {
  return (
    <div>
      <HomeWelcomeComponent 
        title="Indulge in Luxury Living"
        desc="Rent Your Dream Villa Today!"
        buttonText="Book now"
        buttonUrlLink="https://feriehome.com/en/villas-croatia/split/villa-nocturno"
        isDarkText={true}
        backgroundImage={mainVillaDarkImage}
      />
      <HomeFeaturesLayout />
      <SimpleFeatureComponent 
        title="Discover a Unique Experience for Your Vacation"
        desc="Bask in the glorious sunshine of the tropics while lounging beside the pool, or indulge in a candlelit dinner under the stars with a glass of the finest champagne on your private balcony. With our unparalleled amenities, including personal concierge services available to you throughout your entire stay, you can fully immerse yourself in the ultimate vacation experience. Let us take care of everything so you can enjoy a rejuvenating and unforgettable holiday."
        buttonText="Discover"
        buttonLink="/about"
      />

      <div id="gallerySliderTop"></div>

      <ImagesSliderComponent />

      <FooterComponent />
    </div>
  );
};

export default HomePage;
