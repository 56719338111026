import "./apartment-visibility-component.css";
import bookBackground from "../../images/book-background.png";
import calendarImage from "../../images/calendar.svg";
import plusImage from "../../images/plus.svg";
import minusImage from "../../images/minus.svg";
import EnterBookDetailsComponent from "./EnterBookDetailsComponent";
import { useEffect, useState } from "react";
import { Calendar } from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Snackbar from "@mui/material/Snackbar";
import Slide from "@mui/material/Slide";
import { db } from "../../App";
import { doc, getDoc } from "firebase/firestore";

Date.prototype.addDays = function (days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

function TransitionRight(props) {
  return <Slide {...props} direction="right" />;
}

var months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

function validateEmail(email) {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
}

const ApartmentAvailabilityComponent = () => {
  const [allDates, setAllDates] = useState([]);
  const [freeDates, setFreeDates] = useState([]);

  const fetchFreeDates = async () => {
    let docRef = doc(db, "villas", "qo5lBdpMP7DszZECZaFp");
    const document = await getDoc(docRef);

    let availableDates = document.data().availableDates;

    let jsDates = availableDates.map((date) => date.toDate().toString());
    let dates = availableDates.map((date) => date.toDate());

    setAllDates(dates);
    setFreeDates(jsDates);
  };

  useEffect(() => {
    fetchFreeDates();
  }, []);

  const [calendarOpen, setCalendarOpen] = useState(false);

  const openCalendar = () => setCalendarOpen(!calendarOpen);
  const closeCalendar = () => setCalendarOpen(false);

  const [checkIn, setCheckIn] = useState();
  const [checkOut, setCheckOut] = useState();

  const setDateRange = (value) => {
    console.log("setDateRange" + value)
    
    if (value.length === 1) {
      let firstDate = value[0];

      let availableDatesAfterSelectedDate = allDates.filter(
        (date) => date >= firstDate
      );

      let sorted = availableDatesAfterSelectedDate.sort(function (a, b) {
        return a - b;
      });

      let missingDaysAfter = [];

      for (let i = 0; i < sorted.length; i++) {
        var date1 = sorted[i];

        if (i < sorted.length - 1) {
          var date2 = sorted[i + 1];

          let difference = date2.getTime() - date1.getTime();
          let totalDays = Math.ceil(difference / (1000 * 3600 * 24));

          if (totalDays > 1) {
            var missingDate = date1.addDays(1);

            missingDaysAfter.push(missingDate);
          }
        }
      }

      let availableDatesBeforeSelectedDate = allDates.filter(
        (date) => date <= firstDate
      );

      let sortedBefore = availableDatesBeforeSelectedDate.sort(function (a, b) {
        return a - b;
      });

      let missingDaysBefore = [];

      for (let i = 0; i < sortedBefore.length; i++) {
        var date1 = sortedBefore[i];

        if (i < sortedBefore.length - 1) {
          var date2 = sortedBefore[i + 1];

          let difference = date2.getTime() - date1.getTime();
          let totalDays = Math.ceil(difference / (1000 * 3600 * 24));

          if (totalDays > 1) {
            var missingDate = date1.addDays(1);

            //add misingDate to missingDates[] array
            missingDaysBefore.push(missingDate);
          }
        }
      }

      let missingDays = missingDaysAfter.concat(missingDaysBefore);

      if (missingDays.length === 0) return;

      if (missingDaysBefore.length > 0 && missingDaysAfter.length > 0) {
        console.log("1");
        let firstMissingDay = missingDaysBefore[0];
        let lastMissingDay = missingDaysAfter[missingDaysAfter.length - 1];

        let newFreeDates = allDates.filter(
          (date) => date > firstMissingDay && date < lastMissingDay
        );

        let dateStrings = newFreeDates.map((date) => date.toString());

        setFreeDates(dateStrings);
      } else if (missingDaysAfter.length > 0) {
        console.log("2");

        let firstMissing = missingDaysAfter[0];

        let newFreeDates = allDates.filter((date) => date < firstMissing);

        let dateStrings = newFreeDates.map((date) => date.toString());

        setFreeDates(dateStrings);
      } else if (missingDaysBefore.length > 0) {
        console.log("3");
        let firstMissing = missingDaysBefore[missingDaysBefore.length - 1];

        let newFreeDates = allDates.filter((date) => date > firstMissing);

        let dateStrings = newFreeDates.map((date) => date.toString());

        setFreeDates(dateStrings);
      }
    }

    if (value.length === 2) {
      let checkIn = value[0];
      let checkOut = value[1];

      console.log("setcheck" + checkIn)

      setCheckIn(checkIn);
      setCheckOut(checkOut);
    }
  };

  const [adultCount, setAdultCount] = useState(0);
  const [childrenCount, setChildrenCount] = useState(0);

  const addAdult = () => {
    let newAdults = adultCount + 1;
    setAdultCount(newAdults);
  };

  const subtractAdult = () => {
    if (adultCount == 0) return;
    let newAdults = adultCount - 1;
    setAdultCount(newAdults);
  };

  const addChildren = () => {
    let newCount = childrenCount + 1;
    setChildrenCount(newCount);
  };

  const subtractChildren = () => {
    if (childrenCount == 0) return;
    let newCount = childrenCount - 1;
    setChildrenCount(newCount);
  };

  const [openSuccessSnackbar, setSuccessSnackbarOpen] = useState(false);

  const [openErrorSnackbar, setErrorSnackbarOpen] = useState(false);

  const handleSnackbarClose = () => {
    setSuccessSnackbarOpen(false);
    setErrorSnackbarOpen(false);
  };

  const sendRequest = (email, name, phone) => {
    if (!validateEmail(email)) {
      setErrorSnackbarOpen(true);
      return;
    }

    if (name.length < 5) {
      setErrorSnackbarOpen(true);
      return;
    }

    sendEmail(email, name, phone);
  };

  const sendEmail = async (email, name, phone) => {
    let text =
      "Nova rezervacija od " +
      name +
      " na datume " +
      checkIn.toLocaleDateString("uk-Uk") +
      " do " +
      checkOut.toLocaleDateString("uk-Uk") +
      ". Broj mobitela: " +
      phone +
      ". Punoljetni: " +
      adultCount.toString() +
      ", dica: " +
      childrenCount.toString();

    let emailRequest = {
      to: [
        {
          email: "info@villa-nocturno.com",
          name: "Pero Pavlovic",
        },
      ],
      sender: {
        email: email,
        name: name,
      },
      subject: "Nova rezervacija sa stranice",
      htmlContent: text,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "api-key":
          "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",
      },
      body: JSON.stringify(emailRequest),
    };

    let response = await fetch(
      "https://api.sendinblue.com/v3/smtp/email",
      requestOptions
    );

    let json = await response.json();

    setSuccessSnackbarOpen(true);
  };

  return (
    <div className="apartment-availability">
      <Snackbar
        message="Thanks, we got your reservation and will reply soon"
        autoHideDuration={4000}
        open={openSuccessSnackbar}
        onClose={handleSnackbarClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        TransitionComponent={TransitionRight}
      />
      <Snackbar
        message="Provided information is invalid"
        autoHideDuration={4000}
        open={openErrorSnackbar}
        onClose={handleSnackbarClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        TransitionComponent={TransitionRight}
      />
      <div
        style={{ backgroundImage: `url(${bookBackground})` }}
        className="background"
      >
        <div className="introduction" onClick={closeCalendar}>
          <h2>Escape to Your Private Oasis</h2>
          <p>Book this Villa and Enjoy a Relaxing Getaway in Style and Comfort</p>
        </div>
        {calendarOpen ? (
          <div className="calendar-container">
            <Calendar
              defaultValue={[checkIn, checkOut]}
              selectRange={true}
              minDate={new Date()}
              onChange={setDateRange}
              allowPartialRange={true}
              tileDisabled={({ date }) => {
                return !freeDates.includes(date.toString());
              }}
            />
          </div>
        ) : null}

        <div className="choose-date">
          <div className="choose-date-item" onClick={openCalendar}>
            <p className="date-title">Check-In</p>
            <div className="date-container">
              <p className="month-name">
                { checkIn ? months[checkIn.getMonth()] + "." : "MM." }
              </p>
              <p className="month-day">
                {checkIn === undefined ? "dd" : checkIn.getDate().toString()}
              </p>
              <img
                className="calendar"
                src={calendarImage}
                alt="an image showing calendar to book Villa Nocturno"
              />
            </div>
          </div>

          <div className="choose-date-item" onClick={openCalendar}>
            <p className="date-title">check-out</p>
            <div className="date-container">
              <p className="month-name">
                { checkOut ? months[checkIn.getMonth()] + "." : "MM." }
              </p>
              <p className="month-day">
                { checkOut ? checkOut.getDate().toString() : "dd" }
              </p>
              <img
                className="calendar"
                src={calendarImage}
                alt="an image showing calendar to book Villa Nocturno"
              />
            </div>
          </div>

          <div className="choose-people-count">
            <p className="date-title">Adults</p>
            <div className="people-count">
              <img src={minusImage} alt="minus" onClick={subtractAdult} />
              <p>{adultCount}</p>
              <img src={plusImage} alt="plus" onClick={addAdult} />
            </div>
          </div>

          <div className="choose-people-count">
            <p className="date-title">Children</p>
            <div className="people-count">
              <img src={minusImage} alt="minus" onClick={subtractChildren} />
              <p>{childrenCount}</p>
              <img src={plusImage} alt="plus" onClick={addChildren} />
            </div>
          </div>

          <div className="confirm-button-container">
            <a href="#enter-details">
              <button>confirm</button>
            </a>
          </div>
        </div>
      </div>

      <EnterBookDetailsComponent
        checkIn={
          checkIn === undefined ? "" : checkIn.toLocaleDateString("uk-Uk")
        }
        checkOut={
          checkOut ? checkOut.toLocaleDateString("uk-Uk") : ""
        }
        adults={adultCount}
        children={childrenCount}
        validateAndCheckForm={sendRequest}
      />
    </div>
  );
};

export default ApartmentAvailabilityComponent;
