import "./enter-book-details-component.css";

const EnterBookDetailsComponent = (params) => {
  const sendRequest = () => {
    let email = document.getElementById("email").value;

    let name = document.getElementById("name").value;

    let phone = document.getElementById("phone").value;

    params.validateAndCheckForm(email, name, phone);
  };

  return (
    <div className="book-details" id="enter-details">
      <h5>Enter your details</h5>

      <div className="contact-body">
        <div className="left">
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Your email"
          />
          <div className="line" id="input1"></div>

          <input
            type="text"
            id="name"
            name="name"
            placeholder="First and last name"
          />
          <div className="line"></div>

          <input
            type="phone"
            id="phone"
            name="phone"
            placeholder="Your phone number"
          />
          <div className="line" id="input1"></div>
        </div>

        <div className="right">
          <div className="summary-card">
            <h5>request summary</h5>
            <div className="request-row">
              <p className="label">check-in:</p>
              <p className="value">{params.checkIn}</p>
            </div>
            <div className="request-row">
              <p className="label">check-Out:</p>
              <p className="value">{params.checkOut}</p>
            </div>
            <div className="request-row">
              <p className="label">Adults:</p>
              <p className="value">{params.adults}</p>
            </div>
            <div className="request-row">
              <p className="label">Children:</p>
              <p className="value">{params.children}</p>
            </div>
            <button onClick={sendRequest}>send request</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnterBookDetailsComponent;
