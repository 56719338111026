import TestimonialsComponent from "../../components/testimonials/TestimonialsComponent";
import "./testimonials-layout.css";

const TesitmonialsLayout = () => {
  return (
    <div className="testimonials-layout">
      <h2>Reivews</h2>
      <div className="testimonials-container">
        <TestimonialsComponent
          date="29.09.2022."
          review="Such a beautiful modern villa with amazing sunsets. The host Pero is very friendly and was very flexible to accomodate us earlier as we arrived early to Split. The best overall experience I have ever had, highly recommended."
          user="~ Zygimantas Buvarskis"
        />
        <TestimonialsComponent
          date="19.09.2022."
          review="We had an amazing stay at Villa Nocturno! So much thought has gone into the design and build and it felt so luxurious! Pero the owner was in constant contact even before we stayed helping with recommendations and bookings, and was just has helpful once we’d arrived. The pictures looked amazing on Airbnb but in real life the villa and the pool are even more spectacular. The walk up to the villa from the beach/ restaurants is quite steep and would be challenging for someone not as able, but taxi’s and Ubers were easy to get. Couldn’t recommend the villa enough for families and large groups! Thanks again Pero!"
          user="~ Sarah S."
        />
        <TestimonialsComponent
          date="04.09.2022."
          review="Fabulous location, Accommodation was ultra modern and we had all facilities available to us for our stay. Given a very warm welcome by hosts and we would definitely return to this Villa for another holiday. Views were amazing. Simply spoilt !"
          user="~ Chris Hoffman"
        />
        <TestimonialsComponent
          date="06.08.2022."
          review="Perfect villa and host. The host Pero was really helpful and nice. Really recommend to book a stay here to enjoy split and surroundings"
          user="~ Simone Rava"
        />
        <TestimonialsComponent
          date="09.07.2022."
          review="En fantastisk villa! Vi njöt av vår vistelse till 100%. Pero och hans fru var otroligt vänliga, tjänstvilliga och fina värdar som alltid hjälpte oss om vi undrade över något. Huset hade alla bekvämligheter som man kan tänka sig och allt var bara toppen! Vi rekommenderar verkligen deras fina villa."
          user="~ Anette S."
        />
      </div>
    </div>
  );
};

export default TesitmonialsLayout;
