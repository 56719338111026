import "./home-features-layout.css";
import featureOneImage from "../../images/home_feature_1.webp";
import featureSecondImage from "../../images/location-header.webp"
import HomeFeatureComponent from "../../components/home/HomeFeatureComponent";
import ImageComponent from "../../components/slider/ImageComponent"

import { slideImages } from '../../imageData';

const HomeFeaturesLayout = () => {
  return (
    <div className="home-features">

      <HomeFeatureComponent
        title="Welcome to our Luxurious villa nocturno"
        desc="Villa Nocturno is a luxurious rental villa located in Podstrana, Croatia, just a stone's throw away from the bustling city of Split Town in the beautiful Dalmatia State. This stunning villa is situated in a prime location that offers breathtaking views of the crystal clear Adriatic Sea and the surrounding mountains. Villa Nocturno is a perfect getaway for anyone seeking a serene and relaxing vacation, while still being close to all the amenities and attractions that this vibrant region has to offer. With its luxurious amenities and impeccable service, Villa Nocturno promises to deliver a truly unforgettable vacation experience."
        image={featureOneImage}
        imageAlt="Villa with sea view, pool and Jacuzzi"
        imageFirst={false}
        first={false}
      />

      <div className="image-list">
        <div className="img-item"><ImageComponent src={slideImages[7]} alt="Luxury Master Bedroom with terrace and the view" /></div>
        <div className="img-item"><ImageComponent src={slideImages[6]} alt="Luxury Bathroom with hot tube" /></div>
        <div className="img-item"><ImageComponent src={slideImages[22]} alt="Luxury Living room with the view" /></div>
      </div>

    </div>
  );
};

export default HomeFeaturesLayout;
