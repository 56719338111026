import React from "react";
import GoogleMapReact from "google-map-react";
import "./location-component.css";
import LocationPin from "./LocationPin";
import ImageComponent from "../slider/ImageComponent"

const LocationComponent = (props) => {
  let location = {
    address: "Villa Nocturno",
    lat: 43.49314735788854,
    lng: 16.550670271056482
  };

  const handleClick = () => {
    const url = `https://www.google.com/maps?q=${location.lat},${location.lng}`;
    window.open(url, '_blank');
  };

  return (
    <div className="map-container">
      <h3>Distances</h3>

      <div className="location-list">
        <div className="column">
          <ul>
            <li>Private pool: at the Villa</li>
            <li>Beach: 1.3 km</li>
            <li>Mini Marker: 1 km</li>
            <li>Marker: 1.5 km</li>
            <li>Supermarker: 5km</li>
            <li>Restaurants: 700m</li>
          </ul>
        </div>
        <div className="column">
          <ul>
            <li>Split town center: 11 km</li>
            <li>Tennis court: 1.5 km</li>
            <li>Golf course: 3 km</li>
            <li>Sea: 1.3 km</li>
          </ul>
        </div>
        <div className="column">
          <ul>      
            <li>Highway: 22 km</li>
            <li>River: 2.6 km</li>
          </ul>
        </div>
      </div>

      <div className="image-list">
        <div className="img-item"><ImageComponent src="https://firebasestorage.googleapis.com/v0/b/villa-nocturno.appspot.com/o/fh29_crop.jpg?alt=media&token=90796bcf-9f48-4374-a382-1af0be6e7e1a" alt="Luxury Villa" /></div>
        <div className="img-item"><ImageComponent src="https://firebasestorage.googleapis.com/v0/b/villa-nocturno.appspot.com/o/fh22_crop.jpg?alt=media&token=85d5aca8-d32d-4d98-8065-9b11862288bd" alt="Beautiful view of sea and islands" /></div>
        <div className="img-item"><ImageComponent src="https://firebasestorage.googleapis.com/v0/b/villa-nocturno.appspot.com/o/fh28.jpeg?alt=media&token=f6cc0701-b8d7-499f-81a4-b80eb6dd2bfe" alt="Private parking in front of the house" /></div>
      </div>

      <a href="#" onClick={handleClick}><h3>Address: Duge Njive 20F, 21312 Podstrana</h3></a>

      <div className="google-map">
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyALuUo5RWz0Yeio71yrdiw79kmm2igCDeE" }}
          defaultCenter={location}
          defaultZoom={15}
        >
          <LocationPin position={{ lat: location.lat, lng: location.lng }} lat={location.lat} lng={location.lng} handleClick={handleClick} />
        </GoogleMapReact>
      </div>
    </div>
  );
};

export default LocationComponent;
