
export const slideImages = [
    // MAIN
    "https://firebasestorage.googleapis.com/v0/b/villa-nocturno.appspot.com/o/fh29.jpeg?alt=media&token=d75a9a9e-5b85-45a3-8fb0-b42233d3b20f", // 0
    "https://firebasestorage.googleapis.com/v0/b/villa-nocturno.appspot.com/o/fh1.jpeg?alt=media&token=2ee55267-5cbd-4373-85ca-12de683894dc", // 1
    "https://firebasestorage.googleapis.com/v0/b/villa-nocturno.appspot.com/o/1.jpg?alt=media&token=49a0def2-543f-4b5c-b220-9edacf7e5268", // 2
    "https://firebasestorage.googleapis.com/v0/b/villa-nocturno.appspot.com/o/fh23.jpeg?alt=media&token=404a71b6-5b55-45e6-9905-ca0633cc1b3b", // 3
    "https://firebasestorage.googleapis.com/v0/b/villa-nocturno.appspot.com/o/fh25.jpeg?alt=media&token=d7ff5867-c041-405c-93cd-fb1c42373a03", // 4
    "https://firebasestorage.googleapis.com/v0/b/villa-nocturno.appspot.com/o/fh27.jpeg?alt=media&token=f439a374-3e56-48da-88a5-e4891d0c25d5", // 5
    // OTHER  OUTSIDE
    "https://firebasestorage.googleapis.com/v0/b/villa-nocturno.appspot.com/o/10.jpg?alt=media&token=08a75aa7-68ff-4a33-92d9-b783ab738518", // 6
    "https://firebasestorage.googleapis.com/v0/b/villa-nocturno.appspot.com/o/11.jpg?alt=media&token=82ae3b0e-51a5-4dc3-a148-de8b48c27811", // 7
    "https://firebasestorage.googleapis.com/v0/b/villa-nocturno.appspot.com/o/12.jpg?alt=media&token=32725ad3-4d34-4b0f-b6c2-73c8452e80b8", // 8
    "https://firebasestorage.googleapis.com/v0/b/villa-nocturno.appspot.com/o/13.jpg?alt=media&token=484f5028-f23c-4aa6-97cb-341c7d674e8e", // 9
    "https://firebasestorage.googleapis.com/v0/b/villa-nocturno.appspot.com/o/14.jpg?alt=media&token=1269bfeb-8c1c-4543-b4f7-470a542093ea", // 10
    "https://firebasestorage.googleapis.com/v0/b/villa-nocturno.appspot.com/o/15.jpg?alt=media&token=e0a8e972-7525-49d3-9f38-54b2014566b0", // 11
    "https://firebasestorage.googleapis.com/v0/b/villa-nocturno.appspot.com/o/16.jpg?alt=media&token=5d01c22a-f7b1-43f9-9b70-9e7ceb21be66", // 12
    "https://firebasestorage.googleapis.com/v0/b/villa-nocturno.appspot.com/o/17.jpg?alt=media&token=f9a66f00-d0be-4f8a-8481-c84a8775fb6b", // 13
    "https://firebasestorage.googleapis.com/v0/b/villa-nocturno.appspot.com/o/18.jpg?alt=media&token=86a68c54-b1fe-4cf6-98bc-d731746dbbe2", // 14
    "https://firebasestorage.googleapis.com/v0/b/villa-nocturno.appspot.com/o/19.jpg?alt=media&token=d08b72f0-41e1-4bde-ab5f-37341ee9093f", // 15
    "https://firebasestorage.googleapis.com/v0/b/villa-nocturno.appspot.com/o/2.jpg?alt=media&token=084d035a-9645-4167-be6f-7f3f4e461bbb", // 16
    "https://firebasestorage.googleapis.com/v0/b/villa-nocturno.appspot.com/o/20.jpg?alt=media&token=4d002074-ab60-4383-b30d-1f710bad37c5", // 17
    "https://firebasestorage.googleapis.com/v0/b/villa-nocturno.appspot.com/o/21.jpg?alt=media&token=998774b9-8d7e-4010-9871-3104c1ae33dd", // 18
    "https://firebasestorage.googleapis.com/v0/b/villa-nocturno.appspot.com/o/22.jpg?alt=media&token=9a68e7a9-aa27-487c-9ae2-702b6e81292c", // 19
    "https://firebasestorage.googleapis.com/v0/b/villa-nocturno.appspot.com/o/3.jpg?alt=media&token=b7d24d71-17f0-45bc-a1c7-5436f2875f13", // 20
    "https://firebasestorage.googleapis.com/v0/b/villa-nocturno.appspot.com/o/39.jpg?alt=media&token=a403fda5-f803-4c34-bb05-3de9f30e01a5", // 21
    "https://firebasestorage.googleapis.com/v0/b/villa-nocturno.appspot.com/o/4.jpg?alt=media&token=d319bbc5-a0d5-4522-994e-9b46d43f9ebe", // 22
    "https://firebasestorage.googleapis.com/v0/b/villa-nocturno.appspot.com/o/5.jpg?alt=media&token=32e8ddd5-89d8-46a0-9279-5bf0db1770d7", // 23
    "https://firebasestorage.googleapis.com/v0/b/villa-nocturno.appspot.com/o/6.jpg?alt=media&token=d304e62f-efd7-4771-8d99-dafec3fc6392", // 24
    "https://firebasestorage.googleapis.com/v0/b/villa-nocturno.appspot.com/o/7.jpg?alt=media&token=cb0e05aa-357e-4979-8884-e12180318828", // 25
    "https://firebasestorage.googleapis.com/v0/b/villa-nocturno.appspot.com/o/8..jpg?alt=media&token=6714cb88-75f4-403e-bc8d-f59e10cb3dc1", // 26
    "https://firebasestorage.googleapis.com/v0/b/villa-nocturno.appspot.com/o/9.jpg?alt=media&token=fe78c98b-b8eb-4b0b-9026-87fbdee65b8d", // 27
    "https://firebasestorage.googleapis.com/v0/b/villa-nocturno.appspot.com/o/fh24.jpeg?alt=media&token=d7d47f35-a933-4f64-b640-1e937cd6d1dd", // 28
    "https://firebasestorage.googleapis.com/v0/b/villa-nocturno.appspot.com/o/fh26.jpeg?alt=media&token=f0ec8b0d-3063-46ba-8933-4386aac0e210", // 29
    // ROOMS
    "https://firebasestorage.googleapis.com/v0/b/villa-nocturno.appspot.com/o/fh3.jpeg?alt=media&token=a98aa552-b493-491e-80c4-a2cfb6725474", // 30
    "https://firebasestorage.googleapis.com/v0/b/villa-nocturno.appspot.com/o/fh4.jpeg?alt=media&token=61683b2f-1c68-4689-b768-75cdd1c47a03", // 31
    "https://firebasestorage.googleapis.com/v0/b/villa-nocturno.appspot.com/o/fh5.jpeg?alt=media&token=da19d7d4-5681-4b53-8f7e-2967d10a52e0", // 32
    "https://firebasestorage.googleapis.com/v0/b/villa-nocturno.appspot.com/o/fh6.jpeg?alt=media&token=28e99fb4-8321-4c85-aea2-7f0195c1baed", // 33
    "https://firebasestorage.googleapis.com/v0/b/villa-nocturno.appspot.com/o/fh7.jpeg?alt=media&token=e3b192c6-c114-4fd9-997d-c8d222e584be", // 34
    "https://firebasestorage.googleapis.com/v0/b/villa-nocturno.appspot.com/o/fh8.jpeg?alt=media&token=82809cbc-6aa6-4b3a-9636-b6e333e8a6b6", // 35
    "https://firebasestorage.googleapis.com/v0/b/villa-nocturno.appspot.com/o/fh9.jpeg?alt=media&token=cc40ed51-a919-4b28-80bd-e86fe36eaff1", // 36
    "https://firebasestorage.googleapis.com/v0/b/villa-nocturno.appspot.com/o/fh10.jpeg?alt=media&token=5a010eb1-5641-4ff8-95cf-b9ce66840c6f", // 37
    "https://firebasestorage.googleapis.com/v0/b/villa-nocturno.appspot.com/o/fh12.jpeg?alt=media&token=9fb162be-0589-47eb-907d-7698b0fcc211", // 38
    "https://firebasestorage.googleapis.com/v0/b/villa-nocturno.appspot.com/o/fh13.jpeg?alt=media&token=1c1c0ac6-6919-4419-a675-dadb733c2a1e", // 39
    "https://firebasestorage.googleapis.com/v0/b/villa-nocturno.appspot.com/o/fh14.jpeg?alt=media&token=a8f68e2c-0719-44c5-b151-e6497e8126a3", // 40
    "https://firebasestorage.googleapis.com/v0/b/villa-nocturno.appspot.com/o/fh15.jpeg?alt=media&token=129534f7-0c13-4c21-b013-4e58a8347a66", // 41
    "https://firebasestorage.googleapis.com/v0/b/villa-nocturno.appspot.com/o/fh16.jpeg?alt=media&token=309f9afd-777e-440f-ba0b-b2fb1b6ecc26", // 42
    "https://firebasestorage.googleapis.com/v0/b/villa-nocturno.appspot.com/o/fh17.jpeg?alt=media&token=6cad8765-c40e-425e-a254-31da15f9b782", // 43
    "https://firebasestorage.googleapis.com/v0/b/villa-nocturno.appspot.com/o/fh18.jpeg?alt=media&token=45f70182-bc1a-40ce-a91e-743eaaafb8aa", // 44
    "https://firebasestorage.googleapis.com/v0/b/villa-nocturno.appspot.com/o/fh19.jpeg?alt=media&token=8483ab1d-d601-4205-9914-69c62b396b1a", // 45
    "https://firebasestorage.googleapis.com/v0/b/villa-nocturno.appspot.com/o/fh20.jpeg?alt=media&token=c6b307bf-67d7-4d79-bae8-4f8579e47268", // 46
    // OTHER
    "https://firebasestorage.googleapis.com/v0/b/villa-nocturno.appspot.com/o/fh21.jpeg?alt=media&token=160328d8-909a-4bc6-92b3-440d529aa523", // 47
    "https://firebasestorage.googleapis.com/v0/b/villa-nocturno.appspot.com/o/fh22.jpeg?alt=media&token=f4e2f0f8-5d42-46ae-a994-5eb35c7ec884", // 48
    "https://firebasestorage.googleapis.com/v0/b/villa-nocturno.appspot.com/o/fh28.jpeg?alt=media&token=f6cc0701-b8d7-499f-81a4-b80eb6dd2bfe" // 49
  ];