import FooterComponent from "../components/footer/FooterComponent";
import HomeWelcomeComponent from "../components/home/HomeWelcomeComponent";
import LocationComponent from "../components/location/LocationComponent";
import ImagesSliderComponent from "../components/slider/ImagesSliderComponent";
import headerImage from "../images/location-header.webp";

const LocationPage = () => {
  return (
    <div>
      <HomeWelcomeComponent 
        title="LOCATION"
        desc="Discover an unforgettable view of the Mediterranean Sea from our luxurious villa rental."
        buttonText="Book now"
        buttonUrlLink="https://feriehome.com/en/villas-croatia/split/villa-nocturno"
        isDarkText={false}
        backgroundImage={headerImage}
      />
      <LocationComponent />

      <div id="gallerySliderTop"></div>

      <ImagesSliderComponent />

      <FooterComponent />
    </div>
  );
};

export default LocationPage;