import React, { useState } from 'react';
import { useEffect } from "react";
import './Image.css';

const ImageComponent = ({ src, alt }) => {
  const [isEnlarged, setIsEnlarged] = useState(false);

  const handleImageClick = () => {
    setIsEnlarged(true);
  };

  const handleEnlargedClick = () => {
    setIsEnlarged(false);
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleKeyDown = (event) => {
    console.log('KeyDown')
    if (event.key === "Escape") {
      console.log('KeyDown - SEcape')
      setIsEnlarged(false);
    }
  };

  return (
    <div className="image-container">
      <img src={src} alt={alt} onClick={handleImageClick} />
      {isEnlarged && (
        <div className="enlarged-image" onClick={handleEnlargedClick}>
          <img src={src} alt={alt} />
        </div>
      )}
    </div>
  );
};

export default ImageComponent;