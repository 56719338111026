import "./ameneties-component.css";

const AmenetiesComponent = () => {
  return (
    <div className="ameneties-component">

      <h3>Amenities</h3>

      <div className="ameneties-list">
        <div className="column">
          <ul>
            <li>Welcome gift basket</li>
            <li>Personal concierge & house manager</li>
            <li>Linen</li>
            <li>Bathrobes, slippers and toiletries</li>
            <li>Daily towel change</li>
            <li>Weekly linen change (or more frequently, upon request)</li>
            <li>Individually controlled air conditioning & floor heating</li>
            <li>Security cameras</li>
          </ul>
        </div>
        <div className="column">
          <ul>
            <li>Outdoor heating pool with hydromassage</li>
            <li>Outdoor shower</li>
            <li>Jacuzzi</li>
            <li>Sunbeds</li>
            <li>Parasol</li>
            <li>Beach towels</li>
            <li>Summer kitchen</li>
            <li>Safety deposit box</li>
            <li>Wine bar</li>
            <li>Ice maker</li>
          </ul>
        </div>
        <div className="column">
          <ul>      
            <li>Wireless internet</li>
            <li>Fireplace</li>
            <li>Television</li>
            <li>Satellite Cable</li>
            <li>Game Console</li>
            <li>Baby cot (upon request)</li>
            <li>Private parking</li>
          </ul>
        </div>
      </div>

    </div>
  );
};

export default AmenetiesComponent;
