import "./about-component.css";

import ImageComponent from "../slider/ImageComponent"

// import about1image from "https://firebasestorage.googleapis.com/v0/b/villa-nocturno.appspot.com/o/fh19.jpeg?alt=media&token=8483ab1d-d601-4205-9914-69c62b396b1a"

const AboutComponent = () => {
  return (
    <div className="about-component">

      <h3>ABOUT</h3>

      <div className="about-list">
        <div className="column">
          <ul>
            <li className="bold">SLEEPS</li>
            <li>8 persons</li>
          </ul>
        </div>
        <div className="column">
          <ul>
            <li className="bold">BEDROOM</li>
            <li>4 double bedrooms with king-size beds</li>
          </ul>
        </div>
        <div className="column">
          <ul>      
            <li className="bold">BATHROOMS</li>
            <li>4 en-suite bathrooms & 1 toilet</li>
          </ul>
        </div>
        <div className="column">
          <ul>      
            <li className="bold">SIZE</li>
            <li>Entire property 350 m² - interior 270 m²</li>
          </ul>
        </div>
      </div>

      <div className="image-list">
        <div className="img-item"><ImageComponent src="https://firebasestorage.googleapis.com/v0/b/villa-nocturno.appspot.com/o/fh16.jpeg?alt=media&token=309f9afd-777e-440f-ba0b-b2fb1b6ecc26" alt="Luxury Master Bedroom with terrace and the view" /></div>
        <div className="img-item"><ImageComponent src="https://firebasestorage.googleapis.com/v0/b/villa-nocturno.appspot.com/o/fh19.jpeg?alt=media&token=8483ab1d-d601-4205-9914-69c62b396b1a" alt="Luxury Bathroom with hot tube" /></div>
        <div className="img-item"><ImageComponent src="https://firebasestorage.googleapis.com/v0/b/villa-nocturno.appspot.com/o/18.jpg?alt=media&token=86a68c54-b1fe-4cf6-98bc-d731746dbbe2" alt="Luxury Living room with the view" /></div>
      </div>

      <p>With an abundance of natural light pouring through the large glass walls, you'll be able to relax in comfort while enjoying panoramic views of the Adriatic coast and town of Split. The villa features an infinity heated swimming pool with hydromassage and Jacuzzi, perfect for soaking in the sunset. Inside, you'll find a fully equipped kitchen, a spacious living and dining area, and 4 air-conditioned bedrooms, each with its own en-suite bathroom. With amenities such as free WiFi, PS5, and a fireplace, Villa Nocturno has everything you need for a comfortable and relaxing vacation.</p>

    </div>
  );
};

export default AboutComponent;
