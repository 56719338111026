import "./navbar-component.css";
import hamburgerMenuImage from "../../images/hamburger-menu.svg";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Link } from 'react-scroll';
import { useState } from "react";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import close from "../../images/close.png";
import mainVillaLogo from "../../images/main_villa_logo.svg";
import mainVillaLogoDark from "../../images/main_villa_logo_dark.svg";
import { ga, event } from "../../App";

const onContactClick = () => {
  console.log('contact');
  event(ga, 'screen_view', {
    name: 'Contact'
  });
}

const onAboutClick = () => {
  event(ga, 'screen_view', {
    name: 'About'
  });
}

const onLocationClick = () => {
  event(ga, 'screen_view', {
    name: 'Location'
  });
}

const onBookClick = () => {
  event(ga, 'screen_view', {
    name: 'Book'
  });
}

const NavBarComponent = () => {
  let activeStyleLight = {
    color: "#D7B377",
    fontFamily: "DescovarAlpha",
    fontWeight: 400,
  };

  let activeStyleDark = {
    color: "#A88C71",
    fontFamily: "DescovarAlpha",
    fontWeight: 400,
  };

  let nonActiveStyleLight = {
    color: "#000000",
    fontFamily: "DescovarAlpha",
    fontWeight: 400,
  };

  let nonActiveStyleDark = {
    color: "#ffffff",
    fontFamily: "DescovarAlpha",
    fontWeight: 400,
  };

  let activeStyle = activeStyleLight
  let nonActiveStyle = nonActiveStyleLight

  const location = useLocation();

  const [navHeight, setNavHeight] = useState("auto");

  const [hamburgerStyle, setHamburgerStyle] = useState({ display: "none" });

  const onHamburgerMenuClick = () => {
    if (hamburgerStyle.display == "none") {
      setHamburgerStyle({ display: "block" });
      setNavHeight("100vh");
      disableBodyScroll(document);
    } else {
      setHamburgerStyle({ display: "none" });
      setNavHeight("auto");
      enableBodyScroll(document);
    }
  };

  const renderButtonColor = () => {
    // Button
    switch (location.pathname) {
      case '/contact':
        return nonActiveStyleLight
      default:
        return nonActiveStyleDark
    }
  }

  const renderBackgroundImage = () => {
    // Logo 
    switch (location.pathname) {
      case '/book':
      case '/location':
      case '/about':
        return mainVillaLogoDark
      default:
        return mainVillaLogo;
    }
  };

  const isGalleryShown = () => {
    switch (location.pathname) {
      case '/':
      case '/location':
      case '/about':
        return true
      default:
        return false;
    }
  };

  return (
    <div className="navbar" style={{ height: navHeight }}>
      <nav>
        <div className="top">
          <div className="left">
            <ul>
              {/* <li>
                <NavLink
                  to="/amenities"
                  style={({ isActive }) => (isActive ? activeStyleDark : renderButtonColor())}
                >
                  Amenities
                </NavLink>
              </li> */}
              <li>
                <NavLink
                  to="/about"
                  onClick={onAboutClick}
                  style={({ isActive }) => (isActive ? activeStyleLight : renderButtonColor())}
                >
                  About
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/location"
                  onClick={onLocationClick}
                  style={({ isActive }) => (isActive ? activeStyle : renderButtonColor())}
                >
                  Location
                </NavLink>
              </li>
              <li>
              {isGalleryShown() && (
                  <Link
                    to="gallerySliderTop"
                    spy={true}
                    smooth={true}
                    duration={500}
                    offset={80}
                  >
                    Gallery
                  </Link>
                )}
              </li>
            </ul>
          </div>

          <div className="center">
            <NavLink
              to="/"
            >
              <img
                src={renderBackgroundImage()}
                alt="Logo of Villa Nocturno"
              />
            </NavLink>
          </div>

          <div className="right">
            <ul>
              <li>
                <a onClick={onBookClick} style={ renderButtonColor() } href="https://feriehome.com/en/villas-croatia/split/villa-nocturno">
                  Book now
                </a>
              </li>
              <li>
                <NavLink
                  to="/contact"
                  onClick={onContactClick}
                  style={({ isActive }) => (isActive ? activeStyleDark : renderButtonColor())}
                >
                  Contact us
                </NavLink>
              </li>
            </ul>
            <img
              src={hamburgerMenuImage}
              alt="image to open Villa Nocturno pages menu"
              onClick={onHamburgerMenuClick}
            />
          </div>
        </div>
      </nav>
      <div id="hamburger-menu" style={hamburgerStyle}>
        <img
          src={close}
          alt="image to close Villa Nocturno pages menu"
          onClick={onHamburgerMenuClick}
        />
        <ul>
          <li>
            <NavLink onClick={onHamburgerMenuClick} to="/">
              Home
            </NavLink>
          </li>
          {/* <li>
            <NavLink onClick={onHamburgerMenuClick} to="/amenities">
              Amenities
            </NavLink>
          </li> */}
          <li>
            <NavLink onClick={() => {onHamburgerMenuClick(); onAboutClick();}} to="about">
              About
            </NavLink>
          </li>
          <li>
            <NavLink onClick={() => {onHamburgerMenuClick(); onLocationClick();}} to="/location">
              Location
            </NavLink>
          </li>
          <li>
          {isGalleryShown() && (
              <Link
                to="gallerySliderTop"
                spy={true}
                smooth={true}
                duration={500}
                offset={80}
              >
                Gallery
              </Link>
            )}
          </li>
          <li>
            <a onClick={() => {onHamburgerMenuClick(); onBookClick();}} href="https://feriehome.com/en/villas-croatia/split/villa-nocturno">
              Book now
            </a>
          </li>
          <li>
            <NavLink onClick={() => {onHamburgerMenuClick(); onContactClick();}} to="/contact">
              Contact us
            </NavLink>
          </li>
        </ul>
      </div>
      
    </div>
  );
};

export default NavBarComponent;
