import HomeWelcomeComponent from "../components/home/HomeWelcomeComponent";
import ImagesSliderComponent from "../components/slider/ImagesSliderComponent";
import TesitmonialsLayout from "../layouts/testimonials/TestimonialsLayout";
import FooterComponent from "../components/footer/FooterComponent";
import AboutComponent from "../components/about/AboutComponent";
import AmenetiesComponent from "../components/ameneties/AmenetiesComponent";
import amenetiesHeaderImage from "../images/about-header.webp";

const AboutPage = () => {
  return (
    <div>
      <HomeWelcomeComponent 
        title="ABOUT"
        desc="Discover a world of refined taste and exquisite beauty with our villa's exceptional high-end luxury design."
        buttonText="Book now"
        buttonUrlLink="https://feriehome.com/en/villas-croatia/split/villa-nocturno"
        isDarkText={false}
        backgroundImage={amenetiesHeaderImage}
      />

      <AboutComponent />

      <AmenetiesComponent />

      <div id="gallerySliderTop"></div>

      <ImagesSliderComponent />

      <TesitmonialsLayout />

      <FooterComponent />
    </div>
  );
};

export default AboutPage;
