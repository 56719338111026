import React, { Component } from "react";
import markerImage from "../../images/marker.png";

const LocationPin = (props) => {

  return (
    <div onClick={props.handleClick}>
      <img
        style={{
          width: "70px",
          height: "70px",
          marginBottom: "70px"
        }}
        src={markerImage}
        alt="marker image that shows Villa Nocturno location"
      />
    </div>
  );
};

export default LocationPin;
