import "./App.css";
import { BrowserRouter, useLocation } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import { useLayoutEffect } from "react";
import NavBarComponent from "./components/navbar/NavBarComponent";

import HomePage from "./pages/HomePage";
import BookPage from "./pages/BookPage";
import AboutPage from "./pages/AboutPage";
import LocationPage from "./pages/LocationPage";
import AmenetiesPage from "./pages/AmenetiesPage";
import ContactPage from "./pages/ContactPage";

import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyADVE5yJnVUXsVSnaBTIBDlXuelC4S2CWQ",
  authDomain: "villa-nocturno.firebaseapp.com",
  databaseURL: "https://villa-nocturno-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "villa-nocturno",
  storageBucket: "villa-nocturno.appspot.com",
  messagingSenderId: "432465046388",
  appId: "1:432465046388:web:8069c735b2c5954f68da21",
  measurementId: "G-DSN7QJ7VLY"
};

const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const ga = getAnalytics(app);
export const event = logEvent;

export function useFirebaseRoutesAnalytics() {
  const location = useLocation();

  useLayoutEffect(() => {
      const analytics = getAnalytics();
      logEvent(analytics, 'screen_view', {
          firebase_screen: location.pathname,
          firebase_screen_class: 'firebase-routes-analytics',
      });
  }, [location]);

  return null;
}

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <NavBarComponent />
        <Wrapper>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/book" element={<BookPage />} />
            <Route path="/about" element={<AboutPage />} />
            {/* <Route path="/amenities" element={<AmenetiesPage />} /> */}
            <Route path="/location" element={<LocationPage />} />
            <Route path="/contact" element={<ContactPage />} />
          </Routes>
        </Wrapper>
      </BrowserRouter>
    </div>
  );
}

export default App;
