import ApartmentAvailabilityComponent from "../components/book/ApartmentAvailabilityComponent";
import FooterComponent from "../components/footer/FooterComponent";

const BookPage = () => {
  return (
    <div>
      <ApartmentAvailabilityComponent />
      <FooterComponent />
    </div>
  );
};

export default BookPage;
