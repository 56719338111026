import "./home-welcome-components.css";
import { NavLink } from "react-router-dom";

import { ga, event } from "../../App";

const HomeWelcomeComponent = (props) => {
  const buttonText = props.buttonText
  const buttonLink = props.buttonLink
  const buttonUrlLink = props.buttonUrlLink
  const bgImage = props.backgroundImage
  let headerTextColor = "#ffffff"
  
  if (props.isDarkText) {
    headerTextColor = "#292929"
  }

  const onButtonClick = () => {
    event(ga, 'screen_view', {
      name: 'Book'
    });
  }

  return (
    <div className="home-welcome">
      <div 
        style={{ backgroundImage: `url(${bgImage})` }}
        className="welcome-image"
      >
      </div>
      <div className="welcome-header-center">
        <h1 style={{color: headerTextColor}}>{props.title}</h1>
        <p style={{color: headerTextColor}}>{props.desc}</p>
        {buttonLink && <NavLink to={props.buttonLink} onClick={onButtonClick}>
          <button>{props.buttonText}</button>
        </NavLink>}
        {buttonUrlLink && 
          <a onClick={onButtonClick} href="https://feriehome.com/en/villas-croatia/split/villa-nocturno"><button>{props.buttonText}</button></a>
        }
      
      </div>

      <div className="field">
        <div className="mouse"></div>
      </div>

    </div>
  );
};

export default HomeWelcomeComponent;
