import { NavLink } from "react-router-dom";
import "./home-feature-component.css";
import { ga, event } from "../../App";

const HomeFeatureComponent = (props) => {
  let direction = "row";
  let textAlign = "end";
  let paddingTop = "60px";
  let paddingBottom = "60px";

  if (props.imageFirst) {
    direction = "row-reverse";
    textAlign = "start";
  }

  const onButtonClick = () => {
    event(ga, 'screen_view', {
      name: 'Book'
    });
  }

  if (props.first) {
    
  }

  let directionStyle = {
    flexDirection: direction,
    textAlign: textAlign,
    paddingTop: paddingTop,
    paddingBottom: paddingBottom
  };

  return (
    <div className="feature-component" style={directionStyle}>
      <div className="left">
        <h3>{props.title}</h3>
        <p>{props.desc}</p>
        <a onClick={onButtonClick} href="https://feriehome.com/en/villas-croatia/split/villa-nocturno">
          <button>Book now</button>
        </a>
      </div>

      <div className="right">
        <img src={props.image} alt={props.imageAlt}></img>
      </div>
    </div>
  );
};

export default HomeFeatureComponent;
