import { NavLink } from "react-router-dom";
import "./simple-feature-component.css";

import { ga, event } from "../../App";

const onButtonClick = () => {
  event(ga, 'screen_view', {
    name: 'Discover'
  });
}

const SimpleFeatureComponent = (props) => {
  return (
    <div className="simple-feature-component" >
      <h3>{props.title}</h3>
      <p>{props.desc}</p>
      <NavLink onClick={onButtonClick} to={props.buttonLink}>
        <button>{props.buttonText}</button>
      </NavLink>
    </div>
  );
};

export default SimpleFeatureComponent;

