import "./contact-component.css";

const ContactComponent = (params) => {
  const sendRequest = () => {
    let email = document.getElementById("email").value;

    let name = document.getElementById("name").value;

    let message = document.getElementById("message").value;

    params.validateAndCheckForm(email, name, message);
  };

  return (
    <div>
        <div className="contact-us-header">
            <div className="welcome-header-center">
                <h1 style={{color: '#000000'}}>Contact us</h1>
                <p style={{color: '#000000'}}>Reach us at info@villa-nocturno.com</p>
            </div>    
        </div>

        <div className="contact-details" id="enter-details">
        <h5>Enter your details</h5>

        <div className="contact-body">
            <div className="left">
            <input
                type="email"
                id="email"
                name="email"
                placeholder="Your email"
            />
            <div className="line" id="input1"></div>

            <input
                type="text"
                id="name"
                name="name"
                placeholder="First and last name"
            />
            <div className="line"></div>

            <div className="textarea">
                <textarea
                    type="text"
                    id="message"
                    name="message"
                    placeholder="Your Message"
                />
            </div>
            
            <div className="line" id="input1"></div>

            <button onClick={sendRequest}>Send message</button>
            </div>
        </div>
        </div>
    </div>
  );
};

export default ContactComponent;
